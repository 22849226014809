import { Text, View } from "react-native";
import axios from "axios";
import { Formik } from "formik";
import * as Yup from "yup";
import config from "../../config";
import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router";
import {
  Styles,
  TextAreaStyle,
  ToolTipText,
} from "../../styles/UserChatStyles";
import { TextareaAutosize } from "@mui/base";
import CustomText from "./CustomText";
import moment from "moment";
import MainStyles from "../../styles/MainChatStyles";
import { useGlobalState } from "../GlobalStateProvider";

const QuestionForm = ({ form, color, onSubmitForm, index }) => {
  const fontColor = color;
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [showHint, setShowHint] = useState(false);
  const location = useLocation();
  const textareaStyle = { ...TextAreaStyle(), color: fontColor };
  const textareaRef = useRef(null);
  const { addTimeStamp, timeStampArray } = useGlobalState();
  const time_stamp = timeStampArray[index]?.timeStamp;
  const agoTime = time_stamp && moment.utc(time_stamp).fromNow();
  const [ago, setAgo] = useState(agoTime);
  const timeNow = new Date();
  const currentTime = moment(timeNow).format("YYYY-MM-DDTHH:mm:ssZ");
  const pathSegment = location.pathname
    .split("/")
    .filter((segment) => segment !== "");
  const posId = pathSegment[2];
  const [formData, setFormData] = useState({
    answer: "",
  });
  const initialValues = {
    ...formData,
  };

  useEffect(() => {
    const isFirstVisit = localStorage.getItem("isFirstVisit");
    if (isFirstVisit === null || isFirstVisit !== posId) {
      if (timeStampArray.length === 0) {
        setShowHint(true);
        localStorage.setItem("isFirstVisit", posId);
      }
    }
  }, []);

  const validationSchema = Yup.object().shape({
    answer: Yup.string().required("Answer is required"),
  });

  const handleKeyPress = (e, handleSubmit) => {
    if (
      e.nativeEvent.key === "Enter" &&
      !isSubmitted &&
      !e.nativeEvent.shiftKey
    ) {
      e.preventDefault();
      handleSubmit(); // Call handleSubmit to submit the form
      if (textareaRef.current) {
        textareaRef.current.blur(); // Remove focus from the textarea
      }
    }
  };
  useEffect(() => {
    textareaRef.current.focus();
  }, []);
  // useEffect(()=>{
  //   addTimeStamp(currentTime, ago);
  // },[ago]);

  const submitAnswer = async (values) => {
    try {
      setSubmitting(true);
      const headers = {
        "Content-Type": "application/json",
        ...(config.apikey ? { "Vira-AI-API-Key": config.apikey } : {}),
        Accept: "application/json",
      };
      // Request body
      const requestBody = {
        ...values,
      };
      const apiUrl = config.api + form.target;
      const response = await axios.post(apiUrl, requestBody, { headers });
      if (response && response.status === 200) {
        const res = response.data;
        if (res) {
          addTimeStamp(currentTime,moment.utc(currentTime).fromNow())
          .then((timeStamp) => {
            setAgo(moment.utc(timeStamp).fromNow());
            setIsSubmitted(true);
            return onSubmitForm(res);
          })
          .catch((error) => {
            // Handle errors if needed
            console.error(error);
          });
        }
        setSubmitting(false);
      }
    } catch (error) {
      // Handle errors, e.g., display an error message
      config.log("Error:", error.message);
    }
    setShowHint(false);
  };

  const TooltipHint = () => {
    return (
      <Text style={ToolTipText(fontColor)}>
        Pro-tip: if you need a new line, use <em>Shift+Enter</em>. Pressing just{" "}
        <em>Enter</em> submits your answer, which cannot be changed after you
        submit.
      </Text>
    );
  };
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitAnswer}
      validationSchema={validationSchema}
    >
      {({ handleChange, handleBlur, handleSubmit, values, errors }) => (
        <View style={{ width: "100%", display: "flex", gap: 8 }}>
          {showHint && <TooltipHint />}
          <TextareaAutosize
            ref={textareaRef}
            minRows={1}
            style={textareaStyle}
            name="answer"
            type="text"
            autoFocus={false}
            value={values.answer}
            editable={`${!isSubmitted}`}
            placeholder="Enter answer"
            onBlur={handleBlur("answer")}
            onKeyDown={(e) => {
              handleKeyPress(e, handleSubmit);
            }}
            onChange={(e) => handleChange("answer")(e.target.value)}
          />
          {errors.answer && (
            <Text style={Styles.errorText}>{errors.answer}</Text>
          )}
          {submitting && (
            <View style={Styles.submittingContainer}>
              <CustomText style={{ fontSize: 13, color: fontColor }}>
                Sending...
              </CustomText>
            </View>
          )}
            {isSubmitted &&
            (agoTime && (
              <CustomText style={MainStyles.timeSection}>{agoTime}</CustomText>
            ))}
        </View>
      )}
    </Formik>
  );
};
export default QuestionForm;
